import React, { useState } from 'react';
import { UnitSizerPropTypes } from '@v10-codeparva/gatsby-theme-v10-tfw/src/components/unitSizer/UnitSizerPropTypes';
import { Box, UnorderedList, Flex, Text, ListItem } from '@chakra-ui/react';
import { Carousel } from '@v10-codeparva/gatsby-theme-v10-tfw/src/components/unitSizer/Carousel';
import { CallToActionButton } from '@v10-codeparva/gatsby-theme-v10-tfw/src/components/ctaButton';
import { boldText, containerStyle, normalText } from '@v10-codeparva/gatsby-theme-v10-tfw/src/components/unitSizer/constant';
import { CTAButtonTransformer } from '@v10-codeparva/gatsby-theme-v10-tfw/src/transformers/ctaButtonTransformer';

export const UnitSizer = ({
  unitSizer = [],
  ctaButtonConfig,
  locationDetails,
}: UnitSizerPropTypes.IUnitSizerProps) => {
  const ctaConfig = CTAButtonTransformer(locationDetails, ctaButtonConfig);
  const [selectedIndex, setSelectedIndex] = useState(0);
  const totalLength = unitSizer.length - 1;
  return (
    <Flex
      direction={{ base: 'column', sm: 'row' }}
      alignItems={{ base: 'center', sm: 'start' }}
      {...containerStyle}
    >
      <Box
        width={{ sm: '15%', md: '10%', lg: '5%' }}
        textAlign={'center'}
        display={{ base: 'none', sm: 'block' }}
        minWidth="8rem"
      >
        {unitSizer.map(({ heading }, index) => {
          return (
            <Text
              textStyle='body1'
              p='16px 0'
              sx={selectedIndex === index ? boldText : normalText}
              key={index}
              onClick={() => {
                setSelectedIndex(index);
              }}
            >
              {heading}
            </Text>
          );
        })}
      </Box>

      {totalLength !== 0 && (
        <Carousel
          isImage={false}
          selectedIndex={selectedIndex}
          unitSizes={unitSizer}
          setSelectedIndex={setSelectedIndex}
        />
      )}

      <Flex direction={'column'} width={{ base: '90%', sm: 'auto' }} gap={'1rem'} justifyContent='center'>
        <Carousel
          isImage={true}
          selectedIndex={selectedIndex}
          unitSizes={unitSizer}
          setSelectedIndex={setSelectedIndex}
        />
        <Flex justifyContent='center' mt='24px'>
          <Box maxW={{ base: '100%', sm: '66.6%' }} textStyle={'body1'}>
            <Text textStyle='h4'>{`${unitSizer[selectedIndex].heading} Storage Unit`}</Text>
            <Text my={'1rem'}>{unitSizer[selectedIndex].description}</Text>
            <UnorderedList ps={3}>
              {JSON.parse(unitSizer[selectedIndex].storableItems).map((item: any, index: any) => {
                return <ListItem key={index}>{item}</ListItem>;
              })}
            </UnorderedList>
          </Box>
        </Flex>
        <Box textAlign={'center'}>
          <CallToActionButton {...ctaConfig[0]} />
        </Box>
      </Flex>
    </Flex>
  );
};
