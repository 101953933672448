import { phoneNumberFormat } from "@v10-codeparva/gatsby-theme-v10-tfw/src/utils/constant";

export const LoginFormTransformer = (facilityDetails: any) => {
    const facilityTransformerData: any = {};
    facilityTransformerData.facilityMailingAddress = getFacilityMailingAddress(facilityDetails.locationContactDetails);
    facilityTransformerData.facilityAddress = getFacilityAddress(facilityDetails.locationContactDetails);
    facilityTransformerData.facilityMail = getFacilityMail(facilityDetails.locationContactDetails);
    facilityTransformerData.facilityPhone = getFacilityPhone(facilityDetails.locationContactDetails);
    facilityTransformerData.addressMapUrl = facilityDetails.googleMapsUrl;
    facilityTransformerData.mailingAddressMapUrl = facilityDetails.mailingAddressMapUrl;
    return facilityTransformerData;
};

export const getFacilityMail = (contactData: any) => {
    const contactDataArray = contactData.filter((indivData: any) => {
        if (indivData.contactType === 'Email') {
            return true
        }
    });
    return contactDataArray[0].textToDisplay;
};

export const getFacilityPhone = (contactData: any) => {
    const contactDataArray = contactData.filter((indivData: any) => {
        if (indivData.contactType.includes('Phone')) {
            return true
        }
    });
    return phoneNumberFormat(contactDataArray[0].textToDisplay);
}

export const getFacilityMailingAddress = (contactData: any) => {
    const contactDataArray = contactData.filter((indivData: any) => {
        if (indivData.contactType === 'Mailing Address') {
            return true
        }
    });
    return contactDataArray[0]?.textToDisplay || '';
}

export const getFacilityAddress = (contactData: any) => {
    const contactDataArray = contactData.filter((indivData: any) => {
        if (indivData.contactType === 'Address') {
            return true
        }
    });
    return contactDataArray[0].textToDisplay;
}
