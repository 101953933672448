import { mapEnum } from "@v10-codeparva/gatsby-theme-v10-tfw/src/utils/mapEnum";

const AMENITIES_TYPE = [
    'Convenient Location',
    'On Site Manager',
    'Online Bill Pay',
    'Security Cameras',
    'Well List Facility',
    'Brand New Units',
    'Single Level Facility',
    'Drive Up Units',
    'Truck Rentals',
    'Security System',
    'Auto Pay Available',
    'Moving Van Available',
    'Friendly Customer Service',
    'Full Paved Facility',
    'Pin Pad Activated Security Gate',
    'Affordable Rates',
    'Affordable Self Storage Rates',
    'Recreational Vehicle Storage',
    'Boat Storage',
    'Climate Controlled Units Available',
    'Temperature Controlled Units Available',
    'Retail Store On Premises',
    'Packing Supplies Available',
    'Full Fenced Facility',
    'Stellar Customer Service'
]

const AMENITIES_TYPE_OBJECT = mapEnum(AMENITIES_TYPE);

const AMENITIES_ICON_MAPPING = {
    [AMENITIES_TYPE_OBJECT.CONVENIENT_LOCATION]: 'icon-tfw-location',
    [AMENITIES_TYPE_OBJECT.ON_SITE_MANAGER]: 'icon-tfw-manager',
    [AMENITIES_TYPE_OBJECT.ONLINE_BILL_PAY]: 'icon-tfw-online',
    [AMENITIES_TYPE_OBJECT.SECURITY_CAMERAS]: 'icon-tfw-cctv',
    [AMENITIES_TYPE_OBJECT.WELL_LIST_FACILITY]: 'icon-tfw-lit',
    [AMENITIES_TYPE_OBJECT.BRAND_NEW_UNITS]: 'icon-tfw-facility-filled',
    [AMENITIES_TYPE_OBJECT.SINGLE_LEVEL_FACILITY]: 'icon-tfw-facility-filled',
    [AMENITIES_TYPE_OBJECT.DRIVE_UP_UNITS]: 'icon-tfw-car',
    [AMENITIES_TYPE_OBJECT.TRUCK_RENTALS]: 'icon-tfw-truck',
    [AMENITIES_TYPE_OBJECT.SECURITY_SYSTEM]: 'icon-tfw-fence',
    [AMENITIES_TYPE_OBJECT.AUTO_PAY_AVAILABLE]: 'icon-tfw-autopay',
    [AMENITIES_TYPE_OBJECT.MOVING_VAN_AVAILABLE]: 'icon-tfw-drive-through',
    [AMENITIES_TYPE_OBJECT.FRIENDLY_CUSTOMER_SERVICE]: 'icon-tfw-support',
    [AMENITIES_TYPE_OBJECT.FULL_PAVED_FACILITY]: 'icon-tfw-paved',
    [AMENITIES_TYPE_OBJECT.PIN_PAD_ACTIVATED_SECURITY_GATE]: 'icon-tfw-pinpad',
    [AMENITIES_TYPE_OBJECT.AFFORDABLE_RATES]: 'icon-tfw-affordable',
    [AMENITIES_TYPE_OBJECT.AFFORDABLE_SELF_STORAGE_RATES]: 'icon-tfw-affordable',
    [AMENITIES_TYPE_OBJECT.RECREATIONAL_VEHICLE_STORAGE]: 'icon-tfw-car',
    [AMENITIES_TYPE_OBJECT.BOAT_STORAGE]: 'icon-tfw-boat1',
    [AMENITIES_TYPE_OBJECT.CLIMATE_CONTROLLED_UNITS_AVAILABLE]: 'icon-tfw-climate-controller',
    [AMENITIES_TYPE_OBJECT.TEMPERATURE_CONTROLLED_UNITS_AVAILABLE]: 'icon-tfw-climate-controller',
    [AMENITIES_TYPE_OBJECT.RETAIL_STORE_ON_PREMISES]: 'icon-tfw-retail-store',
    [AMENITIES_TYPE_OBJECT.PACKING_SUPPLIES_AVAILABLE]: 'icon-tfw-packaging',
    [AMENITIES_TYPE_OBJECT.FULL_FENCED_FACILITY]: 'icon-tfw-fence',
    [AMENITIES_TYPE_OBJECT.STELLAR_CUSTOMER_SERVICE]: 'icon-tfw-support-filled',
}

export { AMENITIES_ICON_MAPPING, AMENITIES_TYPE }
