import React from 'react';
import { AddressPropTypes } from '@v10-codeparva/gatsby-theme-v10-tfw/src/components/address/AddressPropTypes';
import { Box, Flex, Image, Table, Tbody, Td, Text, Tr, useTheme } from '@chakra-ui/react';
import { ContactDetails } from '@v10-codeparva/gatsby-theme-v10-tfw/src/components/address/ContactDetails';
import { CTAButtons } from '@v10-codeparva/gatsby-theme-v10-tfw/src/components/address/CTAButtons';
import { containerStyles, flexBoxStyles } from '@v10-codeparva/gatsby-theme-v10-tfw/src/components/address/constant';
import '@v10-codeparva/gatsby-theme-v10-tfw/src/style/Address/address.css';
import { convertTo12hours, phoneNumberFormat } from '@v10-codeparva/gatsby-theme-v10-tfw/src/utils/constant';
import { localisable } from '@v10-codeparva/gatsby-theme-v10-tfw/src/generic/localisable';
import { convertToTime } from '@v10-codeparva/gatsby-theme-v10-tfw/src/utils/mapEnum';

export const Address = ({
  variant,
  locationName,
  displayLocationDetails,
  locationHourDetails,
  locationContactDetails = [],
  imgUrl,
  displayContactCard,
  mapSource,
  ctaButtonConfig,
  contactUsVariant,
  displayAmenities,
  amenitiesList,
}: AddressPropTypes.IAddressProps) => {
  const { title, officeHours, storageAccessHours } = locationHourDetails;
  const theme = useTheme();

  const StorageAccessHours = () => {
    return (
      <Flex
        {...flexBoxStyles}
        width={{
          base: 'auto',
          lg: displayContactCard && variant !== 'vertical' ? '328px' : '649px',
        }}
      >
        <Text textStyle={'h3'}>{storageAccessHours.title}</Text>
        <Text p={'16px 12px 16px 16px'} border={'0.6px'} backgroundColor={'border.grey'}>
          {storageAccessHours?.is24Hours
            ? localisable.is24HoursOpen
            : `${convertTo12hours(storageAccessHours.openingTime)} to ${convertTo12hours(
              storageAccessHours.closingTime,
            )}`}
        </Text>
        <CTAButtons ctaButtonConfig={ctaButtonConfig} />
      </Flex>
    );
  };
  const OfficeHours = () => {
    return (
      <Flex {...flexBoxStyles} gap={'16px'}>
        <Text textStyle={'h3'}>{title}</Text>
        {!!officeHours?.length &&
          officeHours.map((hour, index) => {
            return (
              <>
                <Text key={index}>
                  <Text as="b">{hour?.type} :</Text>{' '}
                  {hour?.isClosed
                    ? localisable.closed
                    : `${convertTo12hours(hour?.openingTime)} to ${convertTo12hours(
                      hour.closingTime,
                    )}`}
                </Text>
              </>
            );
          })}
        <Text>
          <Text as="b">{storageAccessHours?.title} : </Text>
          {storageAccessHours?.is24Hours
            ? localisable.is24HoursOpen
            : `${convertTo12hours(storageAccessHours.openingTime)} to ${convertTo12hours(
              storageAccessHours.closingTime,
            )}`}
        </Text>
      </Flex>
    );
  };

  const defaultAddress = () => {
    return (
      <Flex
        gap={{ base: '1.5rem', sm: displayContactCard ? '2.25rem' : '1.5rem' }}
        direction={{ base: 'column', lg: variant === 'vertical' ? 'column' : 'row' }}
        {...containerStyles}
        maxW={{ sm: '720px', md: '1092px' }}
      >
        {displayContactCard && (
          <Flex direction={'column'} width={{ base: 'auto', sm: '720px' }}>
            <ContactDetails
              isContactCard={true}
              locationContactDetails={locationContactDetails}
              locationName={locationName}
              buttonConfig={ctaButtonConfig}
            />
            <Image src={imgUrl} height={'328px'} width={{ base: 'auto', sm: '649px' }} />
          </Flex>
        )}
        <Flex {...flexBoxStyles} width={'auto'}>
          <Flex
            maxWidth={'649px'}
            width={{
              base: 'auto',
              sm: '649px',
              lg:
                (displayLocationDetails && variant !== 'vertical') ||
                  (displayContactCard && variant !== 'vertical')
                  ? '328px'
                  : '649px',
            }}
            height={'282px'}
          >
            <iframe src={mapSource} width={'100%'} />
          </Flex>
          {displayContactCard && <StorageAccessHours />}
        </Flex>

        {!displayContactCard && (
          <Flex direction={{ base: 'column', sm: 'row' }} gap={'24px'} maxWidth={'649px'}>
            {displayLocationDetails && (
              <ContactDetails
                isContactCard={false}
                locationContactDetails={locationContactDetails}
                locationName={locationName}
                buttonConfig={ctaButtonConfig}
              />
            )}
            <OfficeHours />
          </Flex>
        )}
      </Flex>
    );
  };

  const appointmentAddress = () => {
    const rows = amenitiesList.reduce((accumulator, amenity, index) => {
      if (index % 2 === 0) {
        const amenity2 = index + 1 < amenitiesList.length ? amenitiesList[index + 1] : ' ';
        accumulator.push(
          <Tr key={index / 2} w='100%' bg={(index / 2) % 2 === 0 ? '#dee2e6' : 'brand.secondary'} >
            <Td w='50%'>
              <Flex w='100%' gap='8px'>
                <Flex justifyContent='center' alignItems='center' w='20px'>
                  <span
                    style={{
                      width: '8px',
                      height: '8px',
                      borderRadius: '50%',
                      background: theme.colors.brand.tertiary,
                    }}
                  />
                </Flex>
                <Text w='95%' textStyle='body1'>{amenity}</Text>
              </Flex>
            </Td>
            {amenity2 && <Td w='50%'>
              <Flex w='100%' gap='8px'>
                <Flex justifyContent='center' alignItems='center' w='20px'>
                  {(amenity2 !== ' ') && <span
                    style={{
                      width: '8px',
                      height: '8px',
                      borderRadius: '50%',
                      background: theme.colors.brand.tertiary,
                    }}
                  />}
                </Flex>
                <Text w='95%' textStyle='body1'>{amenity2}</Text>
              </Flex>
            </Td>
            }
          </Tr>
        );
      }
      return accumulator;
    }, []);
    return (
      <Flex direction='row' flexWrap='wrap' justifyContent='space-around' gap='24px' maxW={{ base: '100%', sm: '720px', md: '1092px' }} w='100%'>
        <Box flex={{ base: '0 0 100%', md: '0 0 50%' }} maxWidth={{ base: '100%', md: '45%' }} width={{ base: '100%', md: '45%' }}>
          <iframe src={mapSource} width={'100%'} style={{ minHeight: '100%' }} />
        </Box>
        <Flex direction='column' gap='24px' maxWidth={{ base: '100%', md: '50%' }} flex={{ base: '0 0 100%', md: '0 0 50%' }}>
          <Flex direction='column'>
            {!!locationContactDetails.length && locationContactDetails.map((contact: any) => {
              if (contact.type.toLocaleLowerCase().includes('phone')) {
                contact.data = phoneNumberFormat(contact.data);
              }
              return <Flex key={contact.id} direction='row'>
                <Text textStyle='subtitle'>{contact.type}:</Text>
                <Text textStyle='body1'>
                  <a href={contact.redirect}>{contact.data}</a>
                </Text>
              </Flex>
            })}
          </Flex>
          <Flex direction='column'>
            <Text textStyle='subtitle'>Hours:</Text>
            <Text textStyle='subtitle'>NO OFFICE, ALL BUSINESS WILL BE CONDUCTED REMOTELY OR BY APPOINTMENT</Text>
            <Flex direction='row'>
              <Text textStyle='subtitle'>Office Hours:</Text>
              <Text textStyle='body1'>By Appointment Only</Text>
            </Flex>
            <Flex direction='row'>
              <Text textStyle='subtitle'>Access Hours:</Text>
              <Text textStyle='body1'>{`${convertToTime(storageAccessHours?.openingTime)}-${convertToTime(storageAccessHours?.closingTime)}`}</Text>
            </Flex>
          </Flex>
          <Flex wrap='wrap' border='2px solid #dee2e6'>
            {displayAmenities && <Table width="100%">
              <Tbody>{rows}</Tbody>
            </Table>}
          </Flex>
        </Flex>
      </Flex>
    )
  };

  let address;

  if (contactUsVariant === 'default variant') {
    address = defaultAddress();
  } else if (contactUsVariant === 'appointment variant') {
    address = appointmentAddress();
  }

  return address;
};
