import React from "react";
import { Flex, Text } from "@chakra-ui/react"
import { HeaderPropTypes } from "@v10-codeparva/gatsby-theme-v10-tfw/src/components/header/HeaderPropTypes"
import { CTAButtonTypes, CallToActionButton } from "@v10-codeparva/gatsby-theme-v10-tfw/src/components/ctaButton";
import { Icon } from "@v10-codeparva/gatsby-theme-v10-tfw/src/components/icon";
import { EXTERNAL_MAPPING, HEADER_ICONS, OTHER_BUTTON_PROPS } from './constants';

export const RenderHeader = ({ contactType, textToDisplay, contactDetails, ctaConfig, mapUrl, index, mobileVariant }: HeaderPropTypes.IRenderHeaderProps) => {

    const renderCTA = (props: CTAButtonTypes.TCallToAction) => {
        return <CallToActionButton {...props} otherButtonProps={OTHER_BUTTON_PROPS} />;
    };

    return <>
        <Flex
            gap='8px'
            alignItems='center'
            justifyContent={[mobileVariant === 'buttonRight' ? 'flex-start' : 'center', 'center']}
        >
            {<Icon size={'small'} className={HEADER_ICONS[contactType === 'Facility Phone' ? 'Phone' : contactType]} />}
            <Text textStyle='body2'>
                <a href={contactType === 'Address' ? mapUrl : `${EXTERNAL_MAPPING[contactType === 'Facility Phone' ? 'Phone' : contactType]}${textToDisplay}`}>{textToDisplay}</a>
            </Text>
        </Flex>
        {(index === (contactDetails.length - 1)) && !!ctaConfig.length && ctaConfig.map(ctaConfig => renderCTA(ctaConfig))}
    </>
}
