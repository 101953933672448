
export const HEADER_ICONS: Record<string, string> = {
    'Address': 'icon-tfw-location-filled',
    'Email': 'icon-tfw-email-filled',
    'Phone': 'icon-tfw-call-filled',
};

export const SOCIAL_ICONS: Record<string, string> = {
    'Twitter': 'icon-tfw-twitter',
    'Facebook': 'icon-tfw-facebook',
    'LinkedIn': 'icon-tfw-linkedin',
    'Instagram': 'icon-tfw-insta',
};

export const EXTERNAL_MAPPING: Record<string, string> = {
    Email: 'mailto:',
    Phone: 'tel:',
    Address: '',
};

export const OTHER_BUTTON_PROPS = {
    h: '36px',
    w: '85px',
    border: '1px solid common.brown',
    borderRadius: '2px',
    padding: '8px',
    _hover: {
        bg: 'common.brown'
    }
};
