import React from 'react';
import { Box, Flex, Grid, Text } from "@chakra-ui/layout";
import { AmenitiesPropTypes } from "@v10-codeparva/gatsby-theme-v10-tfw/src/components/amenities/AmenitiesPropTypes";
import { Icon, AMENITIES_ICON_MAPPING, CallToActionButton } from "@v10-codeparva/gatsby-theme-v10-tfw/src/components";

export const Amenities = ({ amenitiesWithIconList = [], generalAmenitiesList = [], displayImageSection, imageSection = [] }: AmenitiesPropTypes.IAmenitiesProps) => {
    return (
        <Flex
            justifyContent='center'
            color='text.grey'
            marginTop={{ base: '120px', sm: 'unset' }}
            display={{ base: 'none', sm: 'flex' }}
        >
            <Flex direction='column' width={{ base: '100%', md: '80%' }} gap='16px' alignItems='center'>
                {
                    !!amenitiesWithIconList.length && <Grid
                        templateRows={{ base: 'repeat(2, 1fr)', sm: 'repeat(1, 1fr)' }}
                        templateColumns={{ base: 'repeat(2, 1fr)', sm: 'repeat(4, 1fr)' }}
                        columnGap={{ sm: '8px' }}
                        rowGap={{ base: '16px', sm: 'null' }}
                        width='100%'
                        justifyContent='space-between'
                        maxW={{ sm: '720px', md: '1092px' }}
                    >
                        {
                            amenitiesWithIconList.map(({ id, description }) => {
                                return <Flex
                                    key={id}
                                    direction='column'
                                    maxW='245px'
                                    gap='16px'
                                    justifyItems='center'
                                    alignItems='center'
                                >
                                    <Icon
                                        size={'xl'}
                                        className={AMENITIES_ICON_MAPPING[description]}
                                    />
                                    <Text textStyle='buttonSmall' padding='8px 16px' textAlign='center'>{description}</Text>
                                </Flex>
                            })
                        }
                    </Grid>
                }
                {
                    !!generalAmenitiesList && !!generalAmenitiesList.length && <Grid
                        w='100%'
                        templateRows={{ sm: 'repeat(auto, 1fr)' }}
                        templateColumns={{ base: 'repeat(1, 1fr)', sm: 'repeat(2, 1fr)', md: 'repeat(3, 1fr)' }}
                        padding={{ sm: '0px 56px' }}
                        rowGap='16px'
                        columnGap='auto'
                        justifyItems='center'
                        maxW={{ sm: '720px', md: '1092px' }}
                    >
                        {
                            generalAmenitiesList.map(({ id, description }) => {
                                return <Flex key={id} alignItems='center' gap='16px' padding='8px 16px'>
                                    <Box
                                        as='span'
                                        minW='12px'
                                        h='12px'
                                        borderRadius='50%'
                                        background='brand.tertiary'
                                    />
                                    <Text textStyle='h4'>{description}</Text>
                                </Flex>
                            })
                        }
                    </Grid>
                }
                {
                    displayImageSection && (
                        <Flex w='80%' justifyContent='space-around' direction={{ base: 'column', sm: 'row' }} alignItems='center' maxW={{ sm: '720px', md: '1092px' }}>
                            {imageSection?.map(({ url, imageLink, ctaButtonConfig }, index) => (
                                <Flex key={index} direction='column' gap='24px'>
                                    {imageLink ?
                                        <a href={imageLink} target="_blank">
                                            <img src={url} alt='image' style={{ width: '300px', maxHeight: '300px' }} />
                                        </a> :
                                        <Flex border='6px solid' borderColor='background.main'>
                                            <img src={url} alt='image' style={{ width: '300px', maxHeight: '300px' }} />
                                        </Flex>
                                    }

                                    {ctaButtonConfig && <Flex justifyContent='center'>
                                        <CallToActionButton
                                            {...ctaButtonConfig}
                                            otherButtonProps={{ width: '200px', height: '36px' }}
                                        />
                                    </Flex>}
                                </Flex>
                            ))}
                        </Flex>
                    )
                }
            </Flex>
        </Flex>
    )
}
