import React from 'react';
import { Box, Button, Flex, Input, Spinner, Text } from "@chakra-ui/react"
import { loginConfig } from "@v10-codeparva/gatsby-theme-v10-tfw/src/components/payRent/config"
import { FormField, SubmitButton } from "@v10-codeparva/gatsby-theme-v10-tfw/src/components/form";
import { Form } from "react-final-form";
import { useRef, useState, useEffect } from 'react';
import { LoginPropTypes } from "@v10-codeparva/gatsby-theme-v10-tfw/src/components/payRent/PayRentPropTypes";
import { localisable } from "@v10-codeparva/gatsby-theme-v10-tfw/src/generic/localisable";
import { Link } from "@reach/router";
import { FormApi } from "final-form";
import { Icon } from "@v10-codeparva/gatsby-theme-v10-tfw/src/components/icon";
import { cpLoginFormFooterProps, cpLoginFormHeaderProps, cpLoginFormMainContainerProps,
    loginFormMainContainerProps, loginLoaderStyle, modalButtonProps,
    renderInstructionsProps, submitButtonProps } from "@v10-codeparva/gatsby-theme-v10-tfw/src/components/payRent/constants";
import { renderErrorContainer } from "@v10-codeparva/gatsby-theme-v10-tfw/src/components/payRent/Utils";
import { ChangePasswordLoginForm } from "@v10-codeparva/gatsby-theme-v10-tfw/src/components/payRent/changePassword/ChangePassword";

const navigateTo = (route: string) => {
    const currentPath = typeof window !== 'undefined' ? window.location.pathname : '';
    const pathSegments = currentPath.split('/');
    // Keep only the segments up to and including "pay-rent"
    const basePath = pathSegments.slice(0, pathSegments.indexOf('pay-rent') + 1).join('/');
    return `${basePath}/${route}`;
};

export const LoginForm = ({ onSubmitForm, facilityMail, facilityPhone, cpLoginError, facilityMailingAddress,
    onSubmitCpLoginForm, loginError, mailingAddressMapUrl }: LoginPropTypes.ICombinedLoginProps) => {

    const formProps = useRef({});
    const { userName, password } = loginConfig();
    const [isChangePasswordModalOpen, setIsChangePasswordModalOpen] = useState(false);
    const [isChangePasswordErrorModalOpen, setIsChangePasswordErrorModalOpen] = useState(false);
    const [isLoginErrorExists, setIsLoginErrorExists] = useState(false);
    const [isLoading, setIsLoading] = useState(false);

    useEffect(() => {
        if (cpLoginError) {
            setIsChangePasswordErrorModalOpen(true);
            setIsLoading(false);
        }
        if (loginError) {
            setIsLoginErrorExists(true);
            setIsLoading(false);
        }
    }, [loginError, cpLoginError]);

    const onSubmit = (form: FormApi) => {
        setIsLoading(true);
        setIsLoginErrorExists(false);
        onSubmitForm(form);
    }

    const openChangePasswordModal = () => {
        setIsChangePasswordModalOpen(true);
    };

    const closeChangePasswordModal = () => {
        setIsChangePasswordModalOpen(false);
    };

    const renderForm = (currentFormProps: any) => {
        return (
            <Flex direction='column' gap='24px'>
                <FormField {...userName} {...currentFormProps}>
                    <Input />
                </FormField>
                <FormField {...password} {...currentFormProps}>
                    <Input />
                </FormField>
                <SubmitButton
                    formProps={currentFormProps}
                    label={localisable.login}
                    {...submitButtonProps}
                />
            </Flex>
        )
    }

    const renderLinks = () => {
        return (
            <Flex direction='column' gap='16px'>
                <Link to={navigateTo('forgot-password')}>
                    <Text textStyle='body1' color='button.blue' textDecoration='underline'>{localisable.forgotPassword}</Text>
                </Link>
                <Link to='' onClick={openChangePasswordModal}>
                    <Text textStyle='body1' color='button.blue' textDecoration='underline'>{localisable.changePassword}</Text>
                </Link>
            </Flex>
        )
    }

    const renderInstructions = () => {
        return (
            <Flex direction='column' {...renderInstructionsProps}>
                <Text textStyle='h2'>{localisable.howToLogin}</Text>
                <Flex direction='column' gap='24px'>
                    <Flex direction='column' gap='8px'>
                        <Flex>
                            <span><Text textStyle='subtitle'>{`${localisable.userName}:\u00A0`}</Text></span>
                            <span><Text textStyle='body1'>{`${localisable.userNameExp}`}</Text></span>
                        </Flex>
                        <Flex>
                            <span><Text textStyle='subtitle'>{`${localisable.password}:\u00A0`}</Text></span>
                            <span><Text textStyle='body1'>{`${localisable.passwordExp}`}</Text></span>
                        </Flex>
                    </Flex>
                    <Flex direction='column' gap='16px'>
                        <Text textStyle='subtitle'>{localisable.example}</Text>
                        <Flex direction='column' gap='8px'>
                            <Text textStyle='body1'>{localisable.exampleLine1}</Text>
                            <Text textStyle='body1'>{localisable.exampleLine2}</Text>
                            <Text textStyle='body1'>{localisable.exampleLine3}</Text>
                        </Flex>
                    </Flex>
                    {
                        (facilityMail || facilityPhone || facilityMailingAddress) && <Flex direction='column' gap='16px'>
                            <Text textStyle='subtitle'>{localisable.haveQuestions}</Text>
                            <Flex direction='column' gap='8px'>
                                {/* <Text textStyle='body1'>
                                    <a href={addressMapUrl}>{facilityAddress}</a>
                                </Text> */}
                                <Text textStyle='body1'>
                                    <a href={mailingAddressMapUrl}>{facilityMailingAddress}</a>
                                </Text>
                                <Text textStyle='body1'>
                                    <a href={`mailto:${facilityMail}`}>{facilityMail}</a>
                                </Text>
                                {/* <Text textStyle='body1'>
                                    <a href={`tel:${facilityPhone}`}>{facilityPhone}</a>
                                </Text> */}
                            </Flex>
                        </Flex>
                    }
                </Flex>
            </Flex>
        )
    }

    const RenderChangePasswordLoginForm = ({ }) => {

        const renderHeader = () => {
            return (
                < Flex direction='column' gap='32px' >
                    <Flex {...cpLoginFormHeaderProps}>
                        <Text textStyle='subtitle'>{localisable.forgotPasswordHeader}</Text>
                        <Box cursor='pointer' color='text.grey'>
                            <Icon
                                size={'md'}
                                className='icon-tfw-clear'
                                onClick={closeChangePasswordModal}
                            />
                        </Box>
                    </Flex>
                    <Flex padding='0px 16px'>
                        {isChangePasswordErrorModalOpen && renderErrorContainer(cpLoginError, setIsChangePasswordErrorModalOpen)}
                    </Flex>
                </Flex >
            )
        };

        const renderFooter = () => {
            return (
                <Flex {...cpLoginFormFooterProps} >
                    <Button
                        onClick={closeChangePasswordModal}
                        {...modalButtonProps}
                    >
                        <Text textStyle='buttonSmall' color='button.blue' minW='61px'>{localisable.close}</Text>
                    </Button>
                </Flex>
            )
        };

        return (
            <Flex direction='column' {...cpLoginFormMainContainerProps}>
                {renderHeader()}
                <Flex p='0px 16px'>
                    <ChangePasswordLoginForm onSubmitCpLoginForm={onSubmitCpLoginForm} />
                </Flex>
                {renderFooter()}
            </Flex >
        )
    }

    const Loader = () => {
        return (
            <Flex {...loginLoaderStyle}>
                <Spinner size={'xl'} />
            </Flex>
        );
    };
    return (
        <Flex direction={{ base: 'column', sm: 'row' }} {...loginFormMainContainerProps}>
            {!isChangePasswordModalOpen ? <Flex w={{ base: '100%', md: 'max(36%, 318px)' }} direction='column' gap='24px'>
                {isLoginErrorExists && renderErrorContainer(loginError, setIsLoginErrorExists)}
                {isLoading && <Loader />}
                <Form
                    onSubmit={onSubmit}
                    initialValues={{ userName: '', password: '' }}
                    render={(currentFormProps) => {
                        const { handleSubmit, ...restFormProps } = currentFormProps;
                        formProps.current = restFormProps;
                        return (
                            <form onSubmit={handleSubmit}>
                                {renderForm(currentFormProps)}
                            </form>
                        )
                    }}
                />
                {renderLinks()}
            </Flex> :
                <RenderChangePasswordLoginForm />
            }
            <Flex>
                {renderInstructions()}
            </Flex>
        </Flex>
    )
}
