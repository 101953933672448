import {
    getSurchargesAmount,
    getAuthKey,
    syraPayPayment,
    createTenant,
    performMoveIn,
    performReservation,
    payment,
    publishUserSteps
} from '@v10-codeparva/v10-tfw-revamp-package-api';
import { TPaymentContext } from '@v10-codeparva/gatsby-theme-v10-tfw/src/components/context/PaymentContextTypes';
import { navigate } from '@reach/router';
import { localisable } from '@v10-codeparva/gatsby-theme-v10-tfw/src/generic/localisable';

const getTodaysDate = () => {
    const today = new Date();
    const day = today.getDate();
    const month = today.getMonth() + 1;
    const year = today.getFullYear();
    return `${day}-${month}-${year}`;
}

export const handleSurchargeOrDiscountCalculatorCall = async (
    amount: number,
    paymentMethodId: number,
    setIsPaymentButtonDisabled: (value: React.SetStateAction<boolean>) => void,
    setTotalPaymentAmount: (value: React.SetStateAction<number>) => void
) => {
    setIsPaymentButtonDisabled(true);
    const surChargeRequestBody = {
        paymentMethodId,
        amount,
        cid: process.env.CID || '',
    };
    const surChargeRes = await getSurchargesAmount(surChargeRequestBody);
    const {
        decTotalAmount
    } = surChargeRes;
    setTotalPaymentAmount(decTotalAmount);
    setIsPaymentButtonDisabled(false);
};

export const fetchAuthKeyCall = async (
    totalPaymentAmount: number,
    cToken: string,
    paymentContext: TPaymentContext | null,
    setAuthKey: (value: React.SetStateAction<string>) => void,
    setShowHostedForm: (value: React.SetStateAction<boolean>) => void
) => {
    const authKeyReq = {
        blnForAutopay: false,
        amount: totalPaymentAmount,
        token: cToken || '',
        cid: process.env.CID || '',
    }
    const authKeyResponse = await getAuthKey(authKeyReq);
    const {
        strAuthenticationKey
    } = authKeyResponse;
    paymentContext?.setAuthenticationKey(strAuthenticationKey);
    setAuthKey(strAuthenticationKey);
    setShowHostedForm(true);
};

export const handleFormSubmitCall = async (
    otherResponse: any,
    cardList: {
        [key: string]: any;
    }[],
    // totalAmount: number,
    totalPaymentAmount: number,
    selectedPaymentMode: string,
    cToken: string,
    usingInMoveIn: boolean,
    unitTypeID: number,
    facilityName: string,
    rentType: string,
    moveInDate: string,
    setPaymentErrorResponse: React.Dispatch<React.SetStateAction<object>>,
    signUpForAutopaySelection: boolean,
) => {
    const payTypeDescription = cardList.filter(({ PayTypeID }) => PayTypeID == selectedPaymentMode)[0].PayTypeDescription
    const syraPayHostedReq = {
        useTokenOnFile: false,
        generateDocs: false,
        hostedGatewayResponse: otherResponse,
        paymentAmount: totalPaymentAmount,
        paymentAmountTotal: totalPaymentAmount,
        paymentTypeId: Number(selectedPaymentMode),
        paymentDescription: payTypeDescription,
        token: cToken,
        cid: process.env.CID || '',
        signUpForAutoPay: signUpForAutopaySelection,
    }
    const paymentResponse = await syraPayPayment(syraPayHostedReq);
    const {
        CCApprovalCode,
        blnSuccess: paymentSuccess,
        intErrorCode,
        CCResultCode,
    } = paymentResponse;
    if (paymentSuccess) {
        publishUserSteps({
            cid: process.env.CID || '',
            message: localisable.paymentSuccessful
        });
        if (usingInMoveIn) {
            let apiResponse;
            if (rentType === 'reserve') {
                apiResponse = await performReservation({
                    reserveDate: moveInDate,
                    token: cToken,
                    unitTypeID: String(unitTypeID)
                });
            } else {
                const performMoveInRequest = {
                    moveInDate: getTodaysDate(),
                    unitTypeID: Number(unitTypeID),
                    generateDocument: true,
                    token: cToken
                };
                apiResponse = await performMoveIn(performMoveInRequest);
            }
            const {
                blnSuccess
            } = apiResponse;
            if (blnSuccess) {
                const currentPath = typeof window !== 'undefined' ? window.location.pathname : '';
                const pathSegments = currentPath.split('/');
                const basePath = pathSegments.slice(0, pathSegments.indexOf('view-rates') + 1).join('/');
                navigate(`${basePath}/thank-you`, {
                    state: {
                        paymentAmount: totalPaymentAmount,
                        confirmationString: CCApprovalCode,
                        facilityName: facilityName,
                        isMoveInEnabled: rentType === 'reserve' ? false : true,
                        isReserveEnabled: rentType === 'reserve' ? true : false
                    }
                })
            }

        } else {
            const currentPath = typeof window !== 'undefined' ? window.location.pathname : '';
            const pathSegments = currentPath.split('/');
            const basePath = pathSegments.slice(0, pathSegments.indexOf('pay-rent') + 1).join('/');
            navigate(`${basePath}/thank-you`, {
                state: {
                    paidAmount: totalPaymentAmount,
                    confirmationNumber: CCApprovalCode
                }
            })
        }
    } else {
        const errorObject = {
            errorCode: intErrorCode,
            errorMessage: CCResultCode
        }
        setPaymentErrorResponse(errorObject);
    }
};

export const existingCardPaymentCall = async (
    existingCardDetails: {
        [key: string]: string;
    },
    // totalAmount: number,
    totalPaymentAmount: number,
    selectedPaymentMode: string,
    cToken: string,
    IsAutoPaymentsEnabled: boolean,
    usingInMoveIn: boolean,
    unitTypeID: number,
    facilityName: string,
    rentType: string,
    moveInDate: string,
    setPaymentErrorResponse: React.Dispatch<React.SetStateAction<object>>,
    signUpForAutopaySelection: boolean,
) => {
    const { CCNumber, CCBillingAccountName, CCExpirationMonth, CCExpirationYear, CCBillingAddress, CCBillingZIP, CCAccountCVV2 = '' } = existingCardDetails || {};
    const payloadForExistingCard = {
        useTokenOnFile: true,
        generateDocs: false,
        paymentAmount: totalPaymentAmount,
        paymentAmountTotal: totalPaymentAmount,
        paymentTypeId: Number(selectedPaymentMode),
        paymentDescription: "",
        token: cToken,
        billingAddress: CCBillingAddress,
        cvv2: CCAccountCVV2,
        accountName: CCBillingAccountName,
        accountNumber: CCNumber,
        expirationMonth: CCExpirationMonth,
        expirationYear: CCExpirationYear,
        signUpForAutoPay: signUpForAutopaySelection,
        zip: CCBillingZIP,
        cid: process.env.CID || '',
    };
    const existingCardPayment = await syraPayPayment(payloadForExistingCard);
    const {
        CCApprovalCode,
        blnSuccess: paymentSuccess,
        intErrorCode,
        CCResultCode,
    } = existingCardPayment;
    if (paymentSuccess) {
        publishUserSteps({
            cid: process.env.CID || '',
            message: localisable.paymentSuccessful
        })
        if (usingInMoveIn) {
            let apiResponse;
            if (rentType === 'reserve') {
                apiResponse = await performReservation({
                    reserveDate: moveInDate,
                    token: cToken,
                    unitTypeID: String(unitTypeID)
                });
            } else {
                const performMoveInRequest = {
                    moveInDate: getTodaysDate(),
                    unitTypeID: Number(unitTypeID),
                    generateDocument: true,
                    token: cToken
                };
                apiResponse = await performMoveIn(performMoveInRequest);
            }
            const {
                blnSuccess
            } = apiResponse;
            if (blnSuccess) {
                const currentPath = typeof window !== 'undefined' ? window.location.pathname : '';
                const pathSegments = currentPath.split('/');
                const basePath = pathSegments.slice(0, pathSegments.indexOf('view-rates') + 1).join('/');
                navigate(`${basePath}/thank-you`, {
                    state: {
                        paymentAmount: totalPaymentAmount,
                        confirmationString: CCApprovalCode,
                        facilityName: facilityName,
                        isMoveInEnabled: rentType === 'reserve' ? false : true,
                        isReserveEnabled: rentType === 'reserve' ? true : false
                    }
                })
            };
        } else {
            const currentPath = typeof window !== 'undefined' ? window.location.pathname : '';
            const pathSegments = currentPath.split('/');
            // Keep only the segments up to and including "pay-rent"
            const basePath = pathSegments.slice(0, pathSegments.indexOf('pay-rent') + 1).join('/');
            navigate(`${basePath}/thank-you`, {
                state: {
                    paidAmount: totalPaymentAmount,
                    confirmationNumber: CCApprovalCode
                }
            })
        }
    } else {
        const errorObject = {
            errorCode: intErrorCode,
            errorMessage: CCResultCode
        }
        setPaymentErrorResponse(errorObject);
    }
};

export const createTenantCall = async (moveInConfigs: any) => {
    const { TenantData } = moveInConfigs;
    const createTenantCall = await createTenant({
        tenantInfo: TenantData,
        cid: process.env.CID || '',
    });
    const {
        strTempTenantToken
    } = createTenantCall;
    return strTempTenantToken;
};

export const worldPayPaymentCall = async (
    accountName: string,
    accountNumber: string,
    expirationMonth: string,
    expirationYear: string,
    cvv: string,
    billingAddress: string,
    zip: string,
    signUpForAutoPay: boolean,
    savePaymentInfo: boolean,
    // paymentAmount: string,
    paymentTypeId: number,
    paymentDescription: string,
    token: string,
    usingInMoveIn: boolean,
    totalPaymentAmount: string,
    unitTypeID: number,
    facilityName: string,
    rentType: string,
    moveInDate: string,
) => {
    const worldPayReq = {
        accountNumber,
        accountName,
        expirationMonth,
        expirationYear,
        cvv,
        billingAddress,
        zip,
        signUpForAutoPay,
        savePaymentInfo,
        totalPaymentAmount,
        paymentTypeId,
        paymentDescription,
        token,
        cid: process.env.CID || '',
    }
    const paymentResponse = await payment(worldPayReq);
    const {
        AccessCode,
        blnSuccess: paymentSuccess,
    } = paymentResponse;
    if (paymentSuccess) {
        publishUserSteps({
            cid: process.env.CID || '',
            message: localisable.paymentSuccessful
        })
        if (usingInMoveIn) {
            let apiResponse;
            if (rentType === 'reserve') {
                apiResponse = await performReservation({
                    reserveDate: moveInDate,
                    token: token,
                    unitTypeID: String(unitTypeID)
                });
            } else {
                const performMoveInRequest = {
                    moveInDate: getTodaysDate(),
                    unitTypeID: Number(unitTypeID),
                    generateDocument: true,
                    token: token
                };
                apiResponse = await performMoveIn(performMoveInRequest);
            }
            const {
                blnSuccess
            } = apiResponse;
            if (blnSuccess) {
                const currentPath = typeof window !== 'undefined' ? window.location.pathname : '';
                const pathSegments = currentPath.split('/');
                const basePath = pathSegments.slice(0, pathSegments.indexOf('view-rates') + 1).join('/');
                navigate(`${basePath}/thank-you`, {
                    state: {
                        paymentAmount: totalPaymentAmount,
                        confirmationString: AccessCode,
                        facilityName: facilityName,
                        isMoveInEnabled: rentType === 'reserve' ? false : true,
                        isReserveEnabled: rentType === 'reserve' ? true : false
                    }
                })
            };
        }
        const currentPath = typeof window !== 'undefined' ? window.location.pathname : '';
        const pathSegments = currentPath.split('/');
        const basePath = pathSegments.slice(0, pathSegments.indexOf('pay-rent') + 1).join('/');
        navigate(`${basePath}/thank-you`, {
            state: {
                paidAmount: totalPaymentAmount,
                confirmationNumber: AccessCode
            }
        })
    }
};
