import React from 'react'
import { Flex, Text } from "@chakra-ui/react"
import { HeaderPropTypes } from "@v10-codeparva/gatsby-theme-v10-tfw/src/components/header/HeaderPropTypes"
import { RenderHeader } from "@v10-codeparva/gatsby-theme-v10-tfw/src/components/header/RenderHeader";
import { SOCIAL_ICONS } from '@v10-codeparva/gatsby-theme-v10-tfw/src/components/header/constants';
import { Icon } from "@v10-codeparva/gatsby-theme-v10-tfw/src/components/icon";
import { phoneNumberFormat } from '@v10-codeparva/gatsby-theme-v10-tfw/src/utils/constant';

export const Header = ({ variant,
    mobileVariant,
    contactDetails = [],
    topBarConfig,
    navbarNotice,
    ctaConfig = [],
    mapUrl,
    socialMediaDetails
}: HeaderPropTypes.IHeaderProps) => {

    const { message } = topBarConfig || {};

    if (ctaConfig.length > 1) {
        mobileVariant = 'buttonBottom';
    }

    return (
        <Flex
            color='text.white'
            w='100%'
            direction='column'
        >
            {
                !!message && <Text textStyle='body2' textAlign='center' bg='common.brown' minH='36px' padding='8px 16px'>
                    {message}
                </Text>
            }
            <Flex
                direction={{ base: 'column', sm: "row" }}
                padding={{ base: '16px', md: '8px 72px' }}
                bg='brand.primary'
                gap={['8px', '200px']}
                justifyContent={[mobileVariant === 'buttonRight' ? 'flex-start' : 'center', variant === 'center' ? 'space-around' : variant]}
                alignItems={{ sm: 'center' }}
                flexWrap='wrap'
                display={{ base: 'none', md: 'flex' }}
            >
                <Flex
                    direction={[mobileVariant === 'buttonRight' ? 'row' : 'column', 'row']}
                    justifyContent={[mobileVariant === 'buttonRight' ? 'space-between' : 'center', 'center']}
                    alignItems='center'
                    gap={['8px', '24px']}
                    flexWrap='wrap'
                >
                    {
                        contactDetails.map(({ contactType, textToDisplay }, index) => {
                            if (contactType.toLocaleLowerCase().includes('phone')) {
                                textToDisplay = phoneNumberFormat(textToDisplay);
                            }
                            return !(contactType === 'Mailing Address' || contactType === 'Facility Email') ? <RenderHeader
                                contactType={contactType}
                                textToDisplay={textToDisplay}
                                contactDetails={contactDetails}
                                ctaConfig={ctaConfig}
                                mapUrl={mapUrl}
                                mobileVariant={mobileVariant}
                                index={index}
                            /> : null
                        })
                    }
                </Flex>
                <Flex
                    justifyContent={{ base: 'center' }}
                    gap={'8px'}
                >
                    <>
                        {
                            socialMediaDetails && socialMediaDetails.length > 0 &&
                            socialMediaDetails.map(({ socialMediaType, url }, index) => {
                                return <a
                                    href={url}
                                    key={index}
                                    target='_blank'
                                >
                                    <Icon
                                        size={'small'}
                                        className={SOCIAL_ICONS[socialMediaType]}
                                    />
                                </a>
                            })
                        }
                    </>
                </Flex>
                {
                    !!navbarNotice && <Text textStyle='body2' textAlign={[mobileVariant === 'buttonRight' ? 'left' : 'center', 'center']}>
                        {navbarNotice}
                    </Text>
                }
            </Flex>
        </Flex>
    )
};
